<template>
  <v-dialog v-model="dialog" persistent fullscreen transition="dialog-top-transition" >
    <template v-slot:activator="{ on, attrs }">
      <v-btn outlined rounded color="pediidos" v-bind="attrs" v-on="on" >  {{$t('order.see_order')}}  </v-btn>
    </template>
    <v-card style="overflow-x: hidden;" >

      <v-toolbar dark color="pediidos"  >
        <v-toolbar-title  > {{$t('_order')}} #{{oi}} </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="dialog = !dialog" >{{$t('close')}}</v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card class="mx-auto my-4" max-width="1260" elevation="4" >
        <v-list-item>
            <v-list-item-content>
            <v-list-item-title class="headline font-weight-medium pediidos--text text-center mb-5 " >{{$t('_order')}} #{{oi}}</v-list-item-title>
            <v-row justify="space-between" class="text-center align-center" >
                <template v-for="(state, index) in getDatesStates">
                <v-col cols="12" sm="3" class="pa-0" :key="state.title+index">
                    <v-list class="pa-0 ">
                    <v-avatar :color="state.color">
                        <v-icon dark x-large >{{state.icon}}</v-icon>
                    </v-avatar>
                    <v-list-item >
                        <v-list-item-content class="pt-1">
                        <v-list-item-subtitle class="font-weight-bold " >{{state.date | formatDateShort}}</v-list-item-subtitle>
                        <v-list-item-subtitle >{{state.title}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    </v-list>
                </v-col>
                <v-divider v-if="index < getDatesStates.length - 1" :key="index"></v-divider>
                </template>
            </v-row>
            </v-list-item-content>
        </v-list-item>
      </v-card>
      <v-sheet class="mx-auto py-4 " max-width="1260" >
          <v-row >
              <v-col class="py-0 " >
              <v-card class="ma-0" min-height="165" elevation="4"  >
                  <v-list-item >
                  <v-list-item-content>
                      <v-list-item-title class="headline mb-1">{{$t('shipping_data')}}</v-list-item-title>
                      <v-list-item-subtitle class="subtitle-1" ><b>{{$t('name')}}:</b> {{client.name}}</v-list-item-subtitle>
                      <v-list-item-subtitle class="subtitle-1" ><b>{{$t('address')}}:</b> {{client.address}}</v-list-item-subtitle>
                      <v-list-item-subtitle class="subtitle-1" ><b>{{$t('detail')}}:</b> {{client.details}}</v-list-item-subtitle>
                      <v-list-item-subtitle class="subtitle-1" ><b>{{$t('phone')}}:</b> {{client.phone}}</v-list-item-subtitle>
                      <v-list-item-subtitle class="subtitle-1"><b>{{$t('order.estimated_time')}}:</b> {{resumen.time | formatDate}}</v-list-item-subtitle>
                  </v-list-item-content>
                  </v-list-item>
              </v-card>
              </v-col>
              <v-col class="py-0 " >
              <v-card class="ma-0" min-height="165" elevation="4" >
                  <v-list-item>
                  <v-list-item-content>
                      <v-list-item-title class="headline mb-1">{{$t('cart.order_details')}}</v-list-item-title>
                      <v-list-item-subtitle class="subtitle-1" ><b>{{$t('cart.seller')}}:</b> {{store.name}}</v-list-item-subtitle>
                      <v-list-item-subtitle class="subtitle-1" ><b>{{$t('address')}}:</b> {{store.address}}</v-list-item-subtitle>
                      <v-list-item-subtitle class="subtitle-1" ><b>{{$t('city')}}:</b> {{store.city}}</v-list-item-subtitle>
                      <v-list-item-subtitle class="subtitle-1" ><b>{{$t('phone')}}:</b> {{store.phone}}</v-list-item-subtitle>
                  </v-list-item-content>
                  </v-list-item>
              </v-card>
              </v-col>
          </v-row>
      </v-sheet>
      <v-card class="mx-auto my-4" max-width="1260" elevation="4" >
          <v-list two-line max-height="255" class="overflow-y-auto c-scroll" >
              <template v-for="(item, index) in detailOrder" >
              <v-list-item :key="item.product.product.name+index" >
                  <v-list-item-avatar size="7vh" class="mt-0 mb-0" >
                  <v-img :src="item.product.product.picture"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                  <v-card-text class="subtitle-2 text-uppercase text-ellipsis pa-0">{{item.product.product.name}}</v-card-text>
                  <v-list-item-subtitle>{{$t('cart.price_init')}}: {{item.price_unit | formatCurrency}}</v-list-item-subtitle>
                  <v-list-item-action-text>{{$t('cart.amount')}}: {{item.quanty}}</v-list-item-action-text>
                  </v-list-item-content>
                  <v-list-item-action>
                  <v-list-item-title class="title" >{{item.subtotal | formatCurrency}}</v-list-item-title>
                  </v-list-item-action>
              </v-list-item>
              <v-divider v-if="index < detailOrder.length - 1" :key="index"></v-divider>
              </template>
          </v-list>
      </v-card>
      <v-card class="mx-auto my-3" max-width="1260" elevation="4" >
          <v-list-item >
              <v-list-item-content>
              <v-list-item-title class="headline mb-2"><b>{{$t('cart.subtotal')}}:</b> {{resumen.subtotal | formatCurrency }}</v-list-item-title>
              <v-list-item-title class="headline mb-2"><b>{{$t('cart.delivery_cost')}}:</b> {{resumen.deliveryCost |formatCurrency }}</v-list-item-title>
              <v-list-item-title class="headline mb-2"><b>{{$t('cart.total')}}:</b> {{resumen.total | formatCurrency }}</v-list-item-title>
              </v-list-item-content>
          </v-list-item>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script type="text/javascript">
  import Api from '@/helpers/api.js';
  import { database } from '@/firebase/InitFirebase.js';
  import moment from '@/helpers/DateUtils.js';
  import Utils from '@/helpers/Utils.js';
  import { mapMutations, mapState } from 'vuex';

  export default {
    name: 'OrderDetail',
    props: ['oi','si','ui',],
    data() {
      return {
        dialog: false,
          order_id: '',
          getDatesStates: [],
          detailOrder: [],
          resumen: {
              subtotal: 0,
              deliveryCost: 0,
              total: 0,
              comment: '',
              status_order: '',
              time: '',
              catalog_id: '',
              store_id: '',
          },
          client: {
              id: '',
              name: '',
              address: '',
              details: '',
              phone: '',
              email: '',
          },
          store: {
              id: '',
              name: '',
              phone: '',
              store: '',
              address: '',
              city: '',
          }
      }
    },
    filters: {
        formatDateShort(date) {
            return moment.formatDate(date,'YYYY-MM-DDTHH:mm', 'MMM DD, hh:mm a');
        },
        formatDate(date) {
            return moment.formatDate(date,'YYYY-MM-DD', 'DD MMMM YYYY');
        },
        formatCurrency(currency) {
            return Utils.formatCurrency(currency);
        },
    },
    watch: {
      dialog(value) {
        if(value){
            this.loadData();
        }
      },
    },
    mounted() {},
    methods: {
      ...mapMutations(['APP_LOADER',]),
      async loadData() {
        this.getDetailsOrder();
        this.getOrder();
        this.deleteNotify();
      },
      async getOrder(myTemp) {
        let data = {
          shop_id: this.si,
          order_id: this.oi,
          user_id: this.ui
        }
        Api.post('orders/search/',data).then((result)=> {
          if(result.status < 300) {
            if(result.data.date_reject !== null) {
              this.getDatesStates = [
                {
                  date: result.data.date_register,
                  title: this.$t('order.status_received'),
                  color: 'success',
                  icon: 'mdi-check'
                },
                {
                  date: result.data.date_reject,
                  title: this.$t('order.status_rejected'),
                  color: 'error',
                  icon: 'mdi-close'
                }
              ]
            }else {
              this.getDatesStates = [
                {
                  date: result.data.date_register,
                  title: this.$t('order.status_received'),
                  color: 'success',
                  icon:'mdi-check'
                },
                {
                  date: result.data.date_confirm,
                  title: this.$t('order.status_confirmed'),
                  color: (result.data.date_confirm !== null ? 'success' : 'grey'),
                  icon: (result.data.date_confirm !== null ? 'mdi-check' : 'mdi-clock-outline'),
                },
                {
                  date: result.data.date_end,
                  title: this.$t('order.status_delivered'),
                  color: (result.data.date_end !== null ? 'success' : 'grey'),
                  icon: (result.data.date_end !== null ? 'mdi-check' : 'mdi-clock-outline'),
                }
              ]
            }

            this.resumen.subtotal = result.data.subtotal;
            this.resumen.deliveryCost = result.data.delivery_cost;
            this.resumen.total = result.data.total;
            this.resumen.comment = result.data.comment;
            this.resumen.status_order = result.data.status_order;
            this.resumen.time = (result.time !== '0' ? result.data.time : this.$t('order.status_pending'));
            this.resumen.catalog_id = result.data.catalog_id;
            this.resumen.store_id = result.data.shop.id;

            this.client.id = result.data.user.id
            this.client.name = result.data.user.first_name
            this.client.address = result.data.user_address.address
            this.client.lat = result.data.user_address.lat
            this.client.lng = result.data.user_address.lon
            this.client.details = result.data.user_address.address_detail
            this.client.phone = result.data.user.phone

            this.store.id = result.data.shop.id;
            this.store.idUser = result.data.shop.user.id;
            this.store.name = result.data.shop.user.first_name
            this.store.phone = result.data.shop.phone
            this.store.store = result.data.shop.name
            this.store.address = result.data.shop.address
            this.store.city = result.data.shop.city.name
          }
        }).catch((error)=> {
          console.log('This is error ',error);
          this.goBack();
        })
      },
      async getDetailsOrder(myTemp) {
        let data = {
          shop_id: this.si,
          order_id: this.oi,
          user_id: this.ui
        }
        Api.post('orders/detail/',data).then((result)=> {
          if(result.status < 300) {
            this.detailOrder = result.data;
          }
        }).catch((error)=> {
          console.log('This is error ',error);
          this.goBack();
        })
      },
      async deleteNotify() {
        const notify = database.ref('users/'+this.ui+'/orders/'+this.oi);
        notify.remove();
      },
      goBack(){
        this.$router.push('/orders');
      },
    },
    computed: {
      ...mapState(['appLoader','user'])
    },
  }
</script>

<style type="text/css" >
  .c-scroll::-webkit-scrollbar {
    width: 15px;
    border-radius: 15px;
  }
  .c-scroll::-webkit-scrollbar-track {
    background: #E3F2FD;
    border-radius: 15px;
  }
  .c-scroll::-webkit-scrollbar-thumb {
    background: #2196F3;
    border-radius: 15px;
  }
  .c-scroll::-webkit-scrollbar-thumb:hover {
    background: #2c6add;
  }
</style>